@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body{
    background-color: #000000;
    font-size: 18px;
    line-height: 32px;
    font-family: 'Montserrat', sans-serif;
}

.homePage, .aboutPage{
    background-image: url("/src/assets/images/bg.jpg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.appContainer{
    max-width: 1642px;
    margin: 0 auto;
    padding: 0 30px;
}

.statsSection{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    row-gap: 40px;
    margin-bottom: 70px;
}

.statBlock{
    background-color: rgba(31, 16, 19, 0.55);
    padding-top: 14px;
    padding-bottom: 8px;
    padding-left: 18px;
    padding-right: 31px;
}

.statTitle{
    display: flex;
    gap: 10px;
    margin-bottom: 25px;
    align-items: center;
}

.listLeft li, .listCenter li, .listRight li{
    list-style: none;
}

.listLeft ul, .listCenter ul, .listRight ul{
    padding: 0;
}

.listLeft li:not(:last-child), 
.listCenter li:not(:last-child), 
.listRight li:not(:last-child){
    margin-bottom: 16px;
}

.listLeft li{
    font-size: 15px;
    font-weight: 400;
}

.listCenter li{
    font-size: 16px;
    font-weight: 300;
}

.listRight li{
    font-size: 14px;
    font-weight: 700;
}

.listOne, .listTwo, .listThree{
    display: flex;
    justify-content: space-between;
}

.topTitleSection{
    display: flex;
    margin-bottom: 23px;
}

.hrLeft, .hrRight{
    width: 100%;
}

.hrLeft hr, .hrRight hr{
    border: 1.5px solid #298C96;
}

.hrTitle{
    width: 100%;
    margin: 0 30px;
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
}

.herosection{
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: space-around;
    margin-bottom: 34px;
    align-items: center;
}

.herotitle{
    font-size: 23px;
    padding: 18px;
    border: solid 2px #75d771;
    position: relative;
}

.herotitleList{
    font-size: 18px;
    padding: 18px;
    border: solid 1.5px #75d771;
    position: relative;
    font-weight: 500;
}


.herotitleAbout{
    font-size: 23px;
    padding-right: 66px;
    padding-left: 66px;
    padding-top: 18px;
    padding-bottom: 20px;
    border: solid 1.5px #75d771;
    position: relative;
    max-width: fit-content;
    margin: 0 auto;
    margin-bottom: 52px;
}

.herotitle::before,
.herotitleAbout::before,
.herotitleList::before{
    content: "";
    width: 7px;
    height: 12px;
    position: absolute;
    top: 6px;
    left: -4px;
    background-color: #75d771;
}

.herotitle::after,
.herotitleAbout::after,
.herotitleList::after{
    content: "";
    width: 7px;
    height: 12px;
    position: absolute;
    top: 45px;
    right: -4px;
    background-color: #75d771;
}

.greenBtnLarge{
    text-transform: uppercase;
    display: block;
    text-decoration: none;
    color: white;
    background-color: #2AB048;
    font-size: 17px;
    padding: 12px 40px;
    clip-path: polygon(85% 0, 100% 38%, 100% 100%, 0 100%, 0 0);
    margin-bottom: 12px;
    font-weight: 700;
}

.blackBtnLarge{
    text-transform: uppercase;
    display: block;
    text-decoration: none;
    color: white;
    background-color: #1E2C2F;
    font-size: 15px;
    padding: 8px 40px;
    clip-path: polygon(85% 0, 100% 38%, 100% 100%, 0 100%, 0 0);
    margin-bottom: 12px;
    font-weight: 700;
}

.redBtnLarge{
    text-transform: uppercase;
    display: block;
    text-decoration: none;
    color: white;
    background-color: #C22821;
    font-size: 13px;
    padding: 4px 40px;
    font-weight: 600;
    clip-path: polygon(92% 0, 100% 54%, 100% 100%, 0 100%, 0 0);
}

.greenBtnLarge:hover{
    color: white;
    text-decoration: none;
}

.blackBtnLarge:hover{
    color: white;
    text-decoration: none;
}

.redBtnLarge:hover{
    color: white;
    text-decoration: none;
}

.dataSection th, .dataSection td{
    color: white;
    font-size: 16px;
}

.dataSection th{
    font-weight: 700;
}

.dataSection thead{
    background-color: #2AB048;
}

.tableBtn{
    text-decoration: none;
    color: white;
    border: solid 2px #298C96;
    padding: 6px 18px;
    white-space: nowrap;
}

.tableBtn:hover{
    text-decoration: none;
    color: white;
} 

.tableRow{
    line-height: 62px;
}

.tableRiskCri{
    background-color: #680702;
    padding: 8px 20px;
    clip-path: polygon(85% 0, 100% 38%, 100% 100%, 0 100%, 0 0);
}

.tableRiskHigh{
    background-color: #C22821;
    padding: 8px 20px;
    clip-path: polygon(85% 0, 100% 38%, 100% 100%, 0 100%, 0 0);
}

.tableRiskMed{
    background-color: #EF801A;
    padding: 8px 20px;
    clip-path: polygon(85% 0, 100% 38%, 100% 100%, 0 100%, 0 0);
}

.tableRiskLow{
    background-color: #75d771;
    padding: 8px 20px;
    clip-path: polygon(85% 0, 100% 38%, 100% 100%, 0 100%, 0 0);
}

.tableRiskSafe{
    background-color: #0b942b;
    padding: 8px 20px;
    clip-path: polygon(85% 0, 100% 38%, 100% 100%, 0 100%, 0 0);
}


.tableRow td {
    border-color: #298C96;
    background: rgba(31, 16, 19, 0.55) !important;
}

.tableProjIcon{
    border-radius: 50%;
}

.tableProName img{
    margin-right: 27px;
}

.tablePadLeft{
    padding-left: 30px !important;
}

.footerSection{
    margin-top: 34px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.footerRight{
    display: flex;
    gap: 30px;
}

.footerLeft{
    color: #298C96;
    font-size: 16px;
}

.footerRight a{
    text-decoration: none;
    color: white;
}

.footerRight a:hover{
    text-decoration: none;
    color: #298C96 ;
}

.menuSection{
    margin-top: 36px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.menuLeft{
    display: flex;
    gap: 40px;
}

.menuRight{
    text-transform: uppercase;
    display: flex;
    gap: 35px;
    align-items: center;
}

.aboutTopLogo{
    text-align: center;
    margin-top: 90px;
    margin-bottom: 82px;
}

.aboutHeroTitle{
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 52px;
    line-height: 50px;
}

.aboutHeroSlogon{
    font-size: 22px;
    text-align: center;
    margin-bottom: 35px;
    padding-bottom: 35px;
    padding-left: 60px;
    padding-right: 60px;
    border-bottom: solid 1px #298C96;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.aboutHeroP{
    margin-bottom: 70px;
    font-size: 19px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    padding: 0 30px;
    max-width: 1210px;
    margin: 0 auto;
}

.heroBtns_2{
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    background-color: #2AB048;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 34px;
    clip-path: polygon(92% 0, 100% 54%, 100% 100%, 0 100%, 0 0);
}

.heroBtns_1{
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    background-color: #2AB048;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 34px;
    clip-path: polygon(08% 0, 100% 0, 100% 100%, 0 100%, 0 46%);
}

.heroBtns{
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 70px;
    margin-bottom: 135px;
}

.aboutQuestionBlock{
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 30px;
    column-gap: 80px;
    margin-bottom: 84px;
}

.projectIcon{
    width: 310px;
    text-align: center;
}

.projectIcon img{
    width: 160px;
}

.projectIcon p{
    line-height: normal;
    margin-top: 8px;
}

.projectsIcons a{
    text-decoration: none;
    color: white;
}

.hero2ndTitle{
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 47px;
    line-height: 50px;
}

.aQBLeft{
    display: flex;
    position: relative;
    gap: 42px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.youtubeVideo{
    max-width: 783px;
    margin: 0 auto;
}

.aQBBar{
    width: 3px;
    height: 73px;
    background-color: #298C96;
}

.sQBQuestion{
    font-size: 32px;
    font-weight: 700;
    width: max-content;
}

.aQBText{
    font-size: 19px;
    font-weight: 400;
    line-height: 26px;
}

.aQBRight{
    max-width: 840px;
}

.featuresIcons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 58px;
    margin-bottom: 46px;
}

.featuresText{
    display: flex;
    justify-content: center;
    gap: 38px;
    margin-bottom: 116px;
}

.featuresText p{
    max-width: 270px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

.infoSection{
    margin-bottom: 124px;
}

.infoIcon{
    text-align: center;
    margin-bottom: 52px;
}

.infoTitle{
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 60px;
    text-align: center;
}

.infoTitle2nd{
    margin-top: 81px;
    margin-bottom: 64px;
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    line-height: 50px;
}

.infoP{
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
}

.servicesSection{
    margin-bottom: 160px;
}

.servicesIcon{
    margin-bottom: 33px;
    text-align: center;
}

.servicesTitle{
    margin-bottom: 114px;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    line-height: 50px;
}

.serviceIcon{
    margin-bottom: 31px;
}

.ServiceText{
    font-size: 18px;
    line-height: 22px;
}

.ServiceBlock{
    text-align: center;
    max-width: 286px;
}

.ServicesAll{
    display: flex;
    column-gap: 102px;
    row-gap: 60px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 100px;
}

.projectsSection{
    margin-bottom: 70px;
}

.projectsTitle{
    text-align: center;
    margin-bottom: 78px;
    font-size: 45px;
    font-weight: 700;
    line-height: 50px;
}

.projectsIcons{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;
    font-size: 30px;
    font-weight: 500;
    line-height: 80px;
    margin-bottom: 164px;
}

.projectsStats{
    display: flex;
    column-gap: 110px;
    justify-content: center;
}

.projectStat{
    max-width: 272px;
    text-align: center;
    font-size: 20px;
}

.psTop{
    display: flex;
    column-gap: 25px;
    font-size: 47px;
    justify-content: center;
    margin-bottom: 25px;
    align-items: center;
}

.listLeftLiPop{
    display: flex;
    gap: 10px;
    align-items: center;
}

.psBottom{
    font-size: 18px;
    line-height: 22px;
}

.banner_ads{
    display: flex;
    justify-content: center;
}

.banner_ads img{
    max-width: 100%;
    object-fit: contain;
}

.banner_tablet,
.banner_mobile{
    display: none;
}

.tableHead{
    display: flex;
    flex-wrap: wrap;
    column-gap: 35px;
    row-gap: 25px;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 35px;
}

.tableSeachInput{
    padding: 5px 20px 5px 6px;
    background: rgba(31, 16, 19, 0.55);
    border: solid 1px #298C96;
    color: white;
}

.statTitletxt{
    font-weight: 700;
    font-size: 16px;
}

.hr hr{
    color: #298C96;    ;
    background: #298C96;
    height: 1.5px;
}

.ftb_image{
    text-align: center;
    margin-bottom: 45px;
}

.tableProjFlex{
    display: flex;
    width: max-content;
    align-items: center;
}

@media screen and (min-width: 1440px){
    .ftb_image{
        display: none;
    }
}

@media only screen and (max-width: 1140px){
    .statsSection{
        grid-template-columns: 1fr;
    }
    .featuresIcons{
        display: none;
    }
    .ftb_image{
        display: block;
    }
    .featuresText{
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 860px){
    .aboutQuestionBlock{
        flex-wrap: wrap;
    }
    .featuresIcons_dot{
        display: none;
    }
    .featuresIcons{
        gap: 160px;
    }
    .projectsStats{
        flex-wrap: wrap;
        row-gap: 35px;
    }
    .ServicesAll{
        padding: 0;
    }
}

@media only screen and (max-width: 728px){
    .banner_desk{
        display: none;
    }
    .banner_tablet{
        display: flex;
    }
    .aboutHeroTitle,
    .hero2ndTitle {
        line-height: 50px;
    }
    .herotitleAbout{
        font-size: 18px;
        text-align: center;
        padding: 18px;
    }
    .sQBQuestion{
        width: auto;
    }
}

@media only screen and (max-width: 450px){
    .menuRight{
      display: none;
    }
    .menuLeft{
        justify-content: space-around;
    }
    .menuSection{
        display: block;
        margin-bottom: 25px;
    }
    .listCenter{
        display: none;
    }
    .heroBtns{
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 320px){
    .banner_desk{
        display: none;
    }
    .banner_tablet{
        display: none;
    }
    .banner_mobile{
        display: flex;
    }
}